import React, { useState } from 'react';

import { Container, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import CarouselWrapper from 'components/CarouselWrapper/CarouselWrapper';
import Jumbotron from 'components/Jumbotron';
import MobileGuttersContainer from 'components/MobileGuttersContainer';
import Section from 'components/Section';
import TypographyWithHTML from 'components/TypographyWithHTML/TypographyWithHTML';
import asset_risk_messages from 'messages/asset_risk_messages';

import usePrepareAvailableFilters from '../../hooks/usePrepareAvailableFilters/usePrepareAvailableFilters';
import assetsCategoryFilter from '../../services/_filters/parsers/_assetsFilters/assetsCategoryFilter';
import assetsImportanceFilter from '../../services/_filters/parsers/_assetsFilters/assetsImportanceFilter';
import assetsTaskOwnerFilterFactory from '../../services/_filters/parsers/_assetTasksFilters/assetsTaskOwnerFilterFactory';
import affectsIndividualsFilter from '../../services/_filters/parsers/_riskAnalysisFilters/affectsIndividualsFilter';
import riskAnalysisStatusFilter from '../../services/_filters/parsers/_riskAnalysisFilters/riskAnalysisStatus';
import riskQuadrantFilterFactory from '../../services/_filters/parsers/_riskAnalysisFilters/riskQuadrantFilterFactory';
import booleanFilterFactory from '../../services/_filters/parsers/booleanFilterFactory';
import parseFiltersForRequest, { FiltersT } from '../../services/parseFiltersForRequest/parseFiltersForRequest';

import RiskAnalysisDashboardKPI from './_components/RiskAnalysisDashboardKPI/RiskAnalysisDashboardKPI';
import RiskAnalysisDashboardTable from './_components/RiskAnalysisDashboardTable/RiskAnalysisDashboardTable';

const RiskAnalysisDashboardPage: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up('md'));

  const [filters, setFilters] = useState<FiltersT>({});

  const availableFilters = usePrepareAvailableFilters({
    asset__info__assets_importance: assetsImportanceFilter,
    highest_risk_quadrant: riskQuadrantFilterFactory(asset_risk_messages.risk_table.highest_quadrant_risk_filter),
    status: riskAnalysisStatusFilter,
    has_risks_to_handle: booleanFilterFactory(asset_risk_messages.risk_table.has_risks_to_handle),
    asset__info__contains_sensitive_individual_information: affectsIndividualsFilter,
    asset__category: assetsCategoryFilter,
    asset__owner: assetsTaskOwnerFilterFactory('assetRiskAnalyses'),
  });

  const filtersForRequest = parseFiltersForRequest(filters, {}, availableFilters);

  return (
    <Container disableGutters={!upMd}>
      <Jumbotron>
        <Typography component='h1' variant='h1'>
          {t(asset_risk_messages.dashboard.title)}
        </Typography>
      </Jumbotron>
      <Section gutters narrow>
        <TypographyWithHTML>{t(asset_risk_messages.dashboard.description)}</TypographyWithHTML>
      </Section>
      <CarouselWrapper noTopPadding>
        <RiskAnalysisDashboardKPI filters={filtersForRequest} />
      </CarouselWrapper>
      <MobileGuttersContainer>
        <RiskAnalysisDashboardTable availableFilters={availableFilters} filters={filters} setFilters={setFilters} />
      </MobileGuttersContainer>
    </Container>
  );
};

export default RiskAnalysisDashboardPage;
