import React from 'react';

import { useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import ColoredTile from 'components/ColoredTile';
import organizationRiskAnalysesStatsApi from 'config/api/organizationRiskAnalysesStats/organizationRiskAnalysesStats';
import asset_risk_messages from 'messages/asset_risk_messages';
import { FiltersForRequestT } from 'services/parseFiltersForRequest/parseFiltersForRequest';
import resolveRiskQuadrantColor from 'services/resolveRiskQuadrantColor/resolveRiskQuadrantColor';

type Props = {
  filters: FiltersForRequestT;
};

const RiskAnalysisDashboardKPI: React.FC<Props> = ({ filters }) => {
  const { t } = useTranslation();
  const { data, isLoading } = useQuery(['organizationRiskAnalysesStats', filters], () =>
    organizationRiskAnalysesStatsApi.gatOrganizationRiskAnalysesStats(filters),
  );
  const theme = useTheme();

  return (
    <>
      <ColoredTile
        centered
        color={resolveRiskQuadrantColor(4, theme.palette)}
        description={`${t(asset_risk_messages.risk_quadrant)} 4`}
        disableRipple
        isLoading={isLoading}
        title={data?.riskQuadrant4}
      />
      <ColoredTile
        centered
        color={resolveRiskQuadrantColor(3, theme.palette)}
        description={`${t(asset_risk_messages.risk_quadrant)} 3`}
        disableRipple
        isLoading={isLoading}
        title={data?.riskQuadrant3}
      />
      <ColoredTile
        centered
        color='#ffd6b5'
        description={`${t(asset_risk_messages.risk_quadrant)} 2`}
        disableRipple
        isLoading={isLoading}
        title={data?.riskQuadrant2}
      />
      <ColoredTile
        centered
        color={resolveRiskQuadrantColor(1, theme.palette)}
        description={`${t(asset_risk_messages.risk_quadrant)} 1`}
        disableRipple
        isLoading={isLoading}
        title={data?.riskQuadrant1}
      />
    </>
  );
};

export default RiskAnalysisDashboardKPI;
