import React, { createContext, useState, useContext, useRef, ReactNode, FC, useMemo, useEffect } from 'react';

import { Button, makeStyles, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import * as yup from 'yup';

import DialogGrid from 'components/_dialogs/_components/DialogGrid';
import CenteredGrid from 'components/CenteredGrid/CenteredGrid';
import ColoredButton from 'components/ColoredButton';
import FormSelect from 'components/FormSelect';
import FormTextInput from 'components/FormTextInput';
import authTotpDevicesApi from 'config/api/authTotpDevices/authTotpDevices';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import otp_messages from 'messages/otp_messages';
import validation_messages from 'messages/validation_messages';

const useStyles = makeStyles(theme => ({
  button: {
    // @ts-ignore
    minWidth: theme.sizes.button.narrow,
  },
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: theme.spacing(3),
    width: '100%',
  },
}));

type FulfilledResult = {
  deviceId: number;
  otpToken: string;
};

type OTPAuthModalContextType = {
  showOTPAuthModal: () => Promise<null | FulfilledResult>;
  closeOTPAuthModal: () => void;
};

const OTPAuthModalContext = createContext({} as OTPAuthModalContextType);

const { Provider } = OTPAuthModalContext;

type Props = {
  children: ReactNode;
};

type FormValues = {
  device: number | '';
  otpToken: string;
};

const FORM_ID = 'OTPAuthModalProviderForm';

const OTPAuthModalProvider: FC<Props> = ({ children }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  // const [modalOptions, setModalOptions] = useState<ModalOptions | null>(null);
  const awaitingConfirmation: React.MutableRefObject<{ resolve: (result: null | FulfilledResult) => void } | undefined> = useRef(undefined);

  const { data: devicesList } = useQuery(QUERY_KEYS.GET_OTP_DEVICES_LIST_FOR_AUTH, authTotpDevicesApi.getTotpDevicesForAuth, {
    enabled: open,
  });

  const closeModal = () => {
    setOpen(false);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    formik.resetForm();
  };

  const onSubmit = (formData: FormValues) => {
    if (awaitingConfirmation.current) {
      awaitingConfirmation.current.resolve({ otpToken: formData.otpToken, deviceId: formData.device as number });
    }

    closeModal();
  };

  const showOTPAuthModal = () => {
    setOpen(true);
    return new Promise(resolve => {
      awaitingConfirmation.current = { resolve };
    });
  };

  const onCancel = () => {
    if (awaitingConfirmation.current) {
      awaitingConfirmation.current.resolve(null);
    }

    closeModal();
  };

  const formik = useFormik({
    initialValues: {
      device: '',
      otpToken: '',
    },
    validationSchema: yup.object({
      otpToken: yup.string().required(t(validation_messages.required)),
    }),
    enableReinitialize: true,
    onSubmit,
  });

  const availableDevices: { label: string; key: number }[] = useMemo(() => {
    if (!devicesList) return [];
    return devicesList.filter(({ isConfirmed }) => isConfirmed).map(device => ({ label: device.name, key: device.id }));
  }, [devicesList]);

  const tokenInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (availableDevices.length === 1) {
      // @ts-ignore
      formik.setFieldValue('device', availableDevices[0].key);
    } else if (availableDevices.length > 1) {
      // @ts-ignore
      formik.setFieldValue('device', devicesList.find(({ isDefault }) => isDefault)?.id);
    }
    tokenInputRef.current?.focus();
  }, [availableDevices, devicesList, open]);

  const currentDevice = availableDevices.find(({ key }) => key === formik.values.device)?.label;

  const value = useMemo(() => ({ showOTPAuthModal, closeOTPAuthModal: closeModal }), []);

  const styles = useStyles();
  return (
    <Provider value={value as OTPAuthModalContextType}>
      {children}
      {/* @ts-ignore */}
      <DialogGrid
        dialogActions={
          <div className={styles.wrapper}>
            <Button className={styles.button} onClick={onCancel} variant='outlined'>
              {t(otp_messages.otp_auth_dialog.cancel_button_label)}
            </Button>
            {/* @ts-ignore */}
            <ColoredButton className={styles.button} customColor='secondary' form={FORM_ID} type='submit' variant='outlined'>
              {t(otp_messages.otp_auth_dialog.confirm_button_label)}
            </ColoredButton>
          </div>
        }
        maxWidth='xs'
        onClose={onCancel}
        open={open}
        title={t(otp_messages.otp_auth_dialog.title)}
      >
        <form id={FORM_ID} onSubmit={formik.handleSubmit}>
          <CenteredGrid gridGap={2} tight>
            <Typography>{t(otp_messages.otp_auth_dialog.description, { currentDevice })}</Typography>
            {availableDevices.length > 1 && (
              <CenteredGrid withoutPadding>
                <FormSelect formik={formik} id='device' label={t(otp_messages.otp_auth_dialog.device_label)} options={availableDevices} />
                <Typography variant='caption'>{t(otp_messages.otp_auth_dialog.device_tip)}</Typography>
              </CenteredGrid>
            )}
            <FormTextInput
              formik={formik}
              id='otpToken'
              inputProps={{ autocomplete: 'one-time-code' }}
              inputRef={tokenInputRef}
              label={t(otp_messages.otp_auth_dialog.auth_code_label)}
            />
          </CenteredGrid>
        </form>
      </DialogGrid>
    </Provider>
  );
};

export const useOTPAuthModalContext = () => useContext(OTPAuthModalContext);

export default OTPAuthModalProvider;
