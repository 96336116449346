import axios from 'axios';

import createUrlWithParams from '../../../helpers/createUrlWithParams';

import { parseOrganizationRiskAnalysesStatsForFE } from './organizationRiskAnalysesStats.parsers';
import type ActiveUserRiskAnalysesAssetsForCreateApi from './organizationRiskAnalysesStats.types';

const endpoint = 'api/v1/organization/risk_analyses/stats/';

const organizationRiskAnalysesStatsApi: ActiveUserRiskAnalysesAssetsForCreateApi = {
  gatOrganizationRiskAnalysesStats: async filters => {
    const { data } = await axios.get(createUrlWithParams(endpoint, filters));
    return parseOrganizationRiskAnalysesStatsForFE(data);
  },
};

export default organizationRiskAnalysesStatsApi;
