import React, { useRef, useState } from 'react';

import { ClickAwayListener, Container, makeStyles, MenuItem, MenuList, Paper, Tooltip, Typography } from '@material-ui/core';
import Popper from '@material-ui/core/Popper';
import { Add } from '@material-ui/icons';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import AddNewUserFromFileDialog from 'components/_dialogs/AddNewUserFromFileDialog';
import UserDialog from 'components/_dialogs/UserDialog';
import ColoredButton from 'components/ColoredButton';
import FooterButton from 'components/FooterButton';
import InfoBox from 'components/InfoBox/InfoBox';
import Table from 'components/Table';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import usersEndpoints from 'config/api/users/users';
import { USER_ROLES_ARRAY } from 'config/translatableConstants/USER_ROLES';
import USER_STATUSES from 'config/translatableConstants/USER_STATUSES';
import mergeWithCommas from 'helpers/mergeWithCommas';
import useBoolState from 'hooks/useBoolState';
import usePagination from 'hooks/usePagination';
import general_messages from 'messages/general_messages';
import otp_messages from 'messages/otp_messages';
import table_messages from 'messages/table_messages';
import title_messages from 'messages/title_messages';
import user_messages from 'messages/user_messages';
import userHasTwoFAFilter from 'services/_filters/parsers/userHasTwoFAFilter';
import userStatusFilter from 'services/_filters/parsers/userStatusFilter';
import parseFiltersForRequest from 'services/parseFiltersForRequest';

const RolesCell = ({ roles }) => {
  const maxRoles = 3;
  const { t } = useTranslation();
  const translatedRoles = roles.map(({ role }) => t(USER_ROLES_ARRAY[role]));

  if (roles.length <= maxRoles) return <Typography>{mergeWithCommas(translatedRoles)}</Typography>;

  const rolesToDisplay = translatedRoles.slice(0, maxRoles);
  return (
    <Tooltip
      enterDelay={500}
      title={
        <ul>
          {translatedRoles.map(role => (
            <Typography key={role} component='li'>
              {role}
            </Typography>
          ))}
        </ul>
      }
    >
      <Typography>
        {mergeWithCommas(rolesToDisplay)}
        {t(general_messages.and_more, { count: roles.length - maxRoles })}
      </Typography>
    </Tooltip>
  );
};

const useStyles = makeStyles(theme => ({
  header: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gap: theme.spacing(4),
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `3px solid ${theme.palette.primary[100]}`,
    padding: theme.spacing(5, 0, 3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 0),
    },
  },
  infoBox: {
    gridColumn: '1 / -1',
  },
  popper: {
    zIndex: '1',
  },
}));

const availableFilters = {
  is_active: userStatusFilter,
  has_2fa_enabled: userHasTwoFAFilter,
};

const UsersPage = () => {
  const { t } = useTranslation();
  const styles = useStyles();

  const [filters, setFilters] = useState({});
  const [sort, setSort] = useState({ key: null, direction: null });
  const onSort = (key, direction) => setSort({ key, direction });

  const {
    data: tableData,
    getData: getTableData,
    hasNextPage,
    loading,
    refreshData,
  } = usePagination({
    endpointFunc: usersEndpoints.getAll,
    limits: [30, 20, 10],
    dependencies: [filters, sort],
    sort,
    isGet: true,
    filters: parseFiltersForRequest(filters, {}, availableFilters),
  });

  /* eslint-disable react/destructuring-assignment */
  const dataMapping = [
    {
      id: 'last_name',
      label: t(...table_messages.name),
      get: ({ id, profile }) => (
        <UserAvatar
          lazy
          showName
          userData={{ firstName: profile.first_name, lastName: profile.last_name, imageUrl: profile.image_url }}
          userId={id}
        />
      ),
      width: '21%',
    },
    { id: 'email', label: t(...table_messages.email), get: data => data?.email, width: '25%' },
    {
      id: 'last_login',
      label: t(...table_messages.last_login),
      width: '15%',
      get: data =>
        data?.last_login && (
          <>
            <span>{format(new Date(data.last_login), 'yyyy-MM-dd, HH:mm')}</span>
          </>
        ),
    },
    {
      id: 'is_active',
      width: '7%',
      label: t(...table_messages.status),
      get: data => t(...(data.is_active ? USER_STATUSES.ACTIVE : USER_STATUSES.NOT_VERIFIED)),
      blockSorting: true,
    },
    {
      id: 'has_2fa_enabled',
      width: '7%',
      label: t(...table_messages.use_two_fa),
      get: data => t(...(data.has_2fa_enabled ? general_messages.yes : general_messages.no)),
    },
    {
      id: 'organization_roles',
      width: '25%',
      label: t(...table_messages.roles),
      get: data => (data.organization_roles ? <RolesCell roles={data.organization_roles} /> : null),
      blockSorting: true,
    },
  ];
  /* eslint-enable react/destructuring-assignment */

  const { state: isMenuOpen, setTrue: openMenu, setFalse: closeMenu } = useBoolState(false);
  const { state: newUserFromFileDialog, setTrue: openNewUserFromFileDialog, setFalse: closeNewUserFromFileDialog } = useBoolState(false);
  const [userModal, setUserModal] = useState({ isOpen: false, initialData: null });
  const ref = useRef(null);

  const addNewUserManually = () => {
    closeMenu();
    setUserModal({ isOpen: true, initialData: null });
  };

  const addNewUserFromFile = () => {
    closeMenu();
    openNewUserFromFileDialog();
  };

  const editUser = (e, initialData) => setUserModal({ isOpen: true, initialData });

  const closeNewUserManuallyModal = () => setUserModal({ isOpen: false, initialData: null });

  return (
    <>
      <Container>
        <header className={styles.header}>
          <Typography component='h1' variant='h2'>
            {t(...title_messages.user_administration)}
          </Typography>
          <ClickAwayListener onClickAway={closeMenu}>
            <div>
              <ColoredButton customColor='secondary' endIcon={<Add />} innerRef={ref} onClick={openMenu} variant='outlined'>
                {t(...general_messages.add)}
              </ColoredButton>
              <Popper anchorEl={ref.current} className={styles.popper} open={isMenuOpen}>
                <Paper elevation={3}>
                  <MenuList>
                    <MenuItem onClick={addNewUserManually}>{t(...user_messages.new_user)}</MenuItem>
                    <MenuItem onClick={addNewUserFromFile}>{t(...user_messages.add_from_file)}</MenuItem>
                  </MenuList>
                </Paper>
              </Popper>
            </div>
          </ClickAwayListener>
          <div className={styles.infoBox}>
            <InfoBox content={t(otp_messages.otp_coworkers_encourage.body)} title={t(otp_messages.otp_coworkers_encourage.title)} />
          </div>
        </header>
        <Table
          data={tableData}
          dataMapping={dataMapping}
          filtersService={{ availableFilters, filters, setFilters }}
          onRowClick={editUser}
          onSort={onSort}
          sort={sort}
        />
        {hasNextPage && (
          <FooterButton loading={loading} onClick={getTableData}>
            {t(...general_messages.show_more)}
          </FooterButton>
        )}
      </Container>
      {userModal.isOpen && (
        <UserDialog
          initialData={userModal.initialData}
          onClose={closeNewUserManuallyModal}
          open={userModal.isOpen}
          refreshData={refreshData}
        />
      )}
      {newUserFromFileDialog && (
        <AddNewUserFromFileDialog onClose={closeNewUserFromFileDialog} open={newUserFromFileDialog} refreshData={refreshData} />
      )}
    </>
  );
};

export default UsersPage;
