// TODO unify params, and check isSingleSelect values - needs BE changes in most EPs
import { StaticTableFilter } from '../../types/TableFilter';

export type FilterT = {
  id: string;
  name: string;
};

export type FiltersT = Record<string, FilterT[]>;

export type FiltersForRequestT = Record<string, string[] | string>;

type ParseFiltersForRequest = (
  filters: FiltersT,
  fallbacks?: FiltersForRequestT,
  availableFilters?: Record<string, StaticTableFilter>,
) => FiltersForRequestT;

const parseFiltersForRequest: ParseFiltersForRequest = (filters, fallbacks = {}, availableFilters = {}) => {
  const filtersToRequest: FiltersForRequestT = Object.entries(filters).reduce((acc, [key, values]) => {
    const matchingConfig = availableFilters[key];
    let value: string | string[] = values.map(({ id }) => `${id}`);
    if (matchingConfig?.isSingleSelect) [value] = value;
    const phaseFilter = 'phase';
    if (key === phaseFilter) {
      return {
        ...acc,
        [key]: value,
      };
    }
    return {
      ...acc,
      ...(value?.length && { [key]: value }),
    };
  }, {});
  Object.entries(fallbacks).forEach(([key, value]) => {
    if (!filtersToRequest.hasOwnProperty(key)) {
      filtersToRequest[key] = value;
    }
  });
  return filtersToRequest;
};

export default parseFiltersForRequest;
