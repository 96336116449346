import axios from 'axios';

import createUrlWithParams from 'helpers/createUrlWithParams';
import { Id } from 'types/Id';

import METHOD from '../_methods';

import {
  parseSingleOrganizationAssetTasksForFE,
  parseAllOrganizationAssetTasksForFE,
  parseOrganizationAssetTasksStatsForFE,
} from './organizationAssetTasks.parsers';
import type OrganizationAssetTasksApi from './organizationAssetTasks.types';

const endpoint = 'api/v1/organization/asset_tasks/';

// TODO: remove if react-query
export const legacyOrganizationAssetTasksApi: Record<
  string,
  (baseParams?: { management_report?: Id }) => (params: any) => { url: string; method: string }
> = {
  getAllOrganizationAssetTasks:
    (baseParams = {}) =>
    params => ({ url: createUrlWithParams(endpoint, { ...baseParams, ...params }), method: METHOD.GET }),
};

const organizationAssetTasksApi: OrganizationAssetTasksApi = {
  getAllOrganizationAssetTasks: async () => {
    const { data } = await axios.get(endpoint);
    return parseAllOrganizationAssetTasksForFE(data);
  },
  getSingleOrganizationAssetTasks: async id => {
    const { data } = await axios.get(`${endpoint}${id}/`);
    return parseSingleOrganizationAssetTasksForFE(data);
  },
  getOrganizationAssetTasksStats: async filters => {
    const { data } = await axios.get(createUrlWithParams(`${endpoint}stats/`, filters));
    return parseOrganizationAssetTasksStatsForFE(data);
  },
};

export default organizationAssetTasksApi;
