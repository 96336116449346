import React, { JSX } from 'react';

import clsx from 'clsx';

import FormTitle from 'components/FormTitle';

import useStyles from './CenteredGrid.styles';

// TODO split prop types to wrapper type
type Props =
  | {
      component?: JSX.Element | string;
      className?: string;
      width?: string;
      gridGap?: number;
      children: React.ReactNode | React.ReactNode[];
      title?: string;
      withoutPadding?: boolean;
      rightAdornment?: React.ReactNode;
      tight?: boolean;
    }
  | any;

const CenteredGrid: React.FC<Props> = ({
  component: Component = 'div',
  className,
  width = 'sm',
  gridGap = 0,
  children,
  title,
  withoutPadding,
  rightAdornment,
  tight,
  ...rest
}) => {
  const styles = useStyles({ width, gridGap, withoutPadding, tight });
  return (
    // @ts-ignore
    <Component className={clsx(styles.grid, className)} {...rest}>
      {title && <FormTitle rightAdornment={rightAdornment}>{title}</FormTitle>}
      {children}
    </Component>
  );
};

export default CenteredGrid;
