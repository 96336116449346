import React, { useCallback, useEffect } from 'react';

type Config = {
  intoView: boolean;
  top: number;
  onMount: boolean;
};

const useScrollTo = (ref: React.RefObject<HTMLElement | null>, config?: Config) => {
  const { intoView, onMount, top = 0 } = config || {};

  const scrollTo = useCallback(
    (scrollPosition: number) => {
      if (ref.current) {
        ref.current.scrollTo({
          top: scrollPosition,
          behavior: 'smooth',
        });
      }
    },
    [ref],
  );

  const scrollIntoView = useCallback(() => {
    if (ref.current) ref.current.scrollIntoView();
  }, [ref]);

  useEffect(() => {
    if (onMount) {
      if (intoView) scrollIntoView();
      else scrollTo(top);
    }
  }, [ref, onMount]);

  return { scrollTo, scrollIntoView };
};

export default useScrollTo;
