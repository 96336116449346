import React from 'react';

import { Checkbox, FormControlLabel, FormHelperText, makeStyles } from '@material-ui/core';
import { get } from 'lodash';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  wrapper: {
    position: 'relative',
    '& .MuiIconButton-root': {
      alignSelf: 'end',
    },
  },
  error: {
    marginLeft: '30px',
    marginTop: '0px',
    marginBottom: '10px',
  },
});

const FormCheckbox = ({ label, formik, id, disabled }) => {
  const { touched, errors, handleChange, values, setTouched } = formik;
  const value = get(values, id);
  const error = get(errors, id);
  const isTouched = get(touched, id);

  const onChange = e => {
    setTouched({ ...formik.touched, [id]: true });
    handleChange(e);
  };
  const styles = useStyles();
  return (
    <div className={styles.wrapper}>
      <FormControlLabel
        control={<Checkbox data-testid={`${id}-checkbox`} disabled={disabled} id={id} onChange={onChange} value={value} />}
        label={label}
      />
      <FormHelperText className={styles.error} error>
        {isTouched && error}
      </FormHelperText>
    </div>
  );
};

FormCheckbox.propTypes = {
  formik: PropTypes.shape({
    touched: PropTypes.object,
    errors: PropTypes.object,
    handleChange: PropTypes.func,
    setTouched: PropTypes.func,
    values: PropTypes.object,
  }).isRequired,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

FormCheckbox.defaultProps = {
  disabled: false,
};

export default FormCheckbox;
