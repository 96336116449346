import React, { JSX, useEffect, useMemo, useState } from 'react';

import { Button, IconButton, Typography } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import { differenceBy, get } from 'lodash';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import IncidentReportPrintTemplate, {
  PhaseInfoT,
} from 'components/_print/_templates/IncidentReportPrintTemplate/IncidentReportPrintTemplate';
import PrintComponent from 'components/_print/PrintComponent/PrintComponent';
import CenteredGrid from 'components/CenteredGrid/CenteredGrid';
import ColoredButton from 'components/ColoredButton';
import CustomAccordion from 'components/CustomAccordion';
import DialogViewWrapper from 'components/DialogViewWrapper';
import FormCheckboxTile from 'components/FormCheckboxTile';
import FormFileInput from 'components/FormFileInput';
import FormSelect from 'components/FormSelect';
import FormTextInput from 'components/FormTextInput';
import FormTitle from 'components/FormTitle';
import IncidentLogs from 'components/IncidentLogs';
import InformationSummary from 'components/InformationSummary';
import Loader from 'components/Loader';
import TakenStepsSummary from 'components/TakenStepsSummary';
import incidentReportsEndpoints from 'config/api/incident_reports';
import { IncidentReport, IncidentReportLogDTO } from 'config/api/incident_reports.types';
import GUIDE_STATUSES from 'config/constants/GUIDE_STATUSES';
import INCIDENT_PHASES, { incidentPhaseInOrder, type IncidentPhaseT } from 'config/constants/INCIDENT_PHASES';
import INCIDENT_PRIORITIES from 'config/constants/INCIDENT_PRIORITIES';
import { logObjects } from 'config/constants/logs';
import ROLES from 'config/constants/ROLES';
import EVENTS from 'config/events/pubsub';
import { incidentPhaseResolver } from 'config/translatableConstants/TRANSLATABLE_INCIDENT_PHASES';
import { TRANSLATABLE_INCIDENT_PRIORITIES_DICTIONARY } from 'config/translatableConstants/TRANSLATABLE_INCIDENT_PRIORITIES';
import parseDate from 'helpers/parseDate';
import useApiCall from 'hooks/useApiCall';
import useBoolState from 'hooks/useBoolState';
import useLoadingState from 'hooks/useLoadingState';
import usePermissions from 'hooks/usePermissions';
import useQueryParams from 'hooks/useQueryParams';
import useRedirectFromQuery from 'hooks/useRedirectFromQuery/useRedirectFromQuery';
import useSendingState from 'hooks/useSendingState';
import useSubscription from 'hooks/useSubscription';
import general_messages from 'messages/general_messages';
import guide_messages from 'messages/guide_messages';
import report_messages from 'messages/report_messages';
import title_messages from 'messages/title_messages';
import UnauthorizedPage from 'pages/UnauthorizedPage';
import logEvent from 'services/logEvent';

import AssetsInIncidentReport from './_components/AssetsInIncidentReport/AssetsInIncidentReport';
import IncidentFormBlock from './_components/IncidentFormBlock';
import useStyles from './SingleIncidentReportPage.styles';

const isIncidentManagementPhase = (phase?: IncidentPhaseT) => phase && phase !== INCIDENT_PHASES.IDENTIFY_AND_REPORT;
const isLastPhase = (phase?: IncidentPhaseT) => phase && phase === INCIDENT_PHASES.CLOSED;

const getPreviousPhases = (currentPhase: IncidentPhaseT, isIncidentManagement: boolean) => {
  const incidentPhasesArray = Object.values(INCIDENT_PHASES);
  const currentIndex = incidentPhasesArray.indexOf(currentPhase);
  const previousArray = incidentPhasesArray.slice(0, currentIndex);
  if (isIncidentManagement) {
    return previousArray;
  }
  return previousArray.filter(phase => !isIncidentManagementPhase(phase));
};

const FORM_ID = 'incident_create_form';

const FORM = {
  IS_GDPR: 'is_gdpr',
  IS_ONGOING: 'is_ongoing',
  PRIORITY: 'priority',
  ATTACHMENTS: 'attachments',
  NAME: 'name',
  CATEGORIZATION: 'categorization',
};

type FormT = {
  is_gdpr: boolean;
  is_ongoing: boolean;
  priority: string;
  attachments?: (File | string)[];
  name: string;
  categorization: string;
};

// TODO type incidentData
type IncidentDataT = IncidentReport;

const SingleIncidentReportPage = () => {
  const [isIncidentManager] = usePermissions([ROLES.ORGANIZATION.INCIDENT_MANAGER]);
  const { t } = useTranslation();
  const { report_id: reportId } = useParams() as { report_id: string };
  const { apiCall } = useApiCall();
  const history = useHistory();
  const { deleteParam, queryParams } = useQueryParams<'localProgressId' | 'fromGuide' | 'readOnly'>();
  const { enqueueSnackbar } = useSnackbar();
  const { redirect } = useRedirectFromQuery();

  const [incidentData, setIncidentData] = useState<IncidentDataT | null>(null);
  const [attachmentsInDb, setAttachmentsInDb] = useState([]);

  const disabled = isLastPhase(incidentData?.phase) || incidentData?.progress_status === GUIDE_STATUSES.COMPLETED || queryParams.readOnly;
  const { loading, setLoading, setLoaded } = useLoadingState(false);
  const { sending, setSending, setSent } = useSendingState(false);

  const { state: accessDenied, setTrue: setAccessDeniedTrue } = useBoolState(false);

  const formik = useFormik<FormT>({
    onSubmit: () => {},
    initialValues: {
      is_gdpr: false,
      is_ongoing: false,
      priority: '',
      name: '',
      categorization: '',
    },
    enableReinitialize: true,
  });

  // LOADING AND CREATING INCIDENT
  const updateFormik = (data: IncidentDataT) => {
    // @ts-ignore
    const newFieldsData = data.fields_data.reduce((acc, { field_id, value }) => {
      acc[field_id.toString() as keyof FormT] = value || '';
      return acc;
    }, {} as Partial<FormT>);
    const attachments = get(data, FORM.ATTACHMENTS, []);
    setAttachmentsInDb(attachments);
    newFieldsData.attachments = attachments;
    const formData: FormT = {
      is_gdpr: get(data, 'is_gdpr', false),
      is_ongoing: get(data, 'is_ongoing', false),
      priority: get(data, 'priority', INCIDENT_PRIORITIES.MEDIUM),
      name: get(data, 'name', ''),
      categorization: get(data, 'categorization', ''),
    };
    formik.setValues({ ...newFieldsData, ...formData });
  };

  const initReportFromGuide = async () => {
    const { status, data } = await apiCall(incidentReportsEndpoints.createFromGuideAsActiveUser(), {
      data: { guide_progress_id: queryParams.localProgressId },
    });

    if (status < 300) {
      setIncidentData(data);
      updateFormik(data);
      deleteParam('localProgressId');
      const pathnameWithId = history.location.pathname + data.id + history.location.search;
      history.replace(pathnameWithId);
    }
  };

  const getData = async (silent?: boolean) => {
    if (!silent) setLoading();
    const { data, status } = await apiCall(
      isIncidentManager
        ? incidentReportsEndpoints.getOneAsIncidentManager(reportId)
        : incidentReportsEndpoints.getOneAsActiveUser(reportId),
      {},
      { showError: false },
    );
    if (data.detail === 'Not found.') {
      setAccessDeniedTrue();
      return;
    }
    if (status >= 400) {
      enqueueSnackbar(data.detail, { variant: 'error' });
      redirect({ moveBack: true });
    } else {
      setIncidentData(data);
      if (!silent) updateFormik(data);
      setLoaded();
    }
  };

  useSubscription(EVENTS.INCIDENT_GUIDE_RELATED_ASSETS_UPDATED, () => getData(true));

  useEffect(() => {
    const init = async () => {
      setLoading();
      if (queryParams.localProgressId) {
        deleteParam('fromGuide');
        redirect();
        await initReportFromGuide();
      } else {
        await getData();
      }
      setLoaded();
    };

    init();
  }, []);

  // SAVING
  const getApiCallsForAttachments = () => {
    const { values } = formik;
    const toRemove = values.attachments ? differenceBy(attachmentsInDb, values.attachments, 'id') : [];
    const toAdd = values.attachments ? values.attachments.filter(file => file instanceof File) : [];
    const calls: Promise<any>[] = [];
    toRemove.forEach(({ id }) => {
      calls.push(apiCall(incidentReportsEndpoints.removeAttachment(id)));
    });
    toAdd.forEach(file => {
      const data = new FormData();
      data.append('file', file);
      calls.push(
        apiCall(
          isIncidentManager
            ? incidentReportsEndpoints.addAttachmentAsIncidentManager(reportId)
            : incidentReportsEndpoints.addAttachmentAsActiveUser(reportId),
          { data },
          { showError: false },
        ),
      );
    });
    return calls;
  };

  const onSave = async () => {
    setSending();
    const { values } = formik;
    const steps = Object.entries(formik.values).reduce((result, [field_id, value]) => {
      if (!Object.values(FORM).includes(field_id)) {
        // @ts-ignore
        result.push({ field_id, value });
      }
      return result;
    }, []);
    const data = {
      is_gdpr: values.is_gdpr,
      is_ongoing: values.is_ongoing,
      priority: values.priority,
      name: values.name,
      categorization: values.categorization,
    };
    const apiCallsForAttachments = getApiCallsForAttachments();
    let success;
    const promises = [...apiCallsForAttachments];
    if (steps.length) {
      if (isIncidentManager) {
        promises.push(apiCall(incidentReportsEndpoints.updateFieldsAsIncidentManager(reportId), { data: steps }));
      } else {
        promises.push(apiCall(incidentReportsEndpoints.updateFieldsAsActiveUser(reportId), { data: steps }));
      }
      const [{ status: fieldsStatus }] = await Promise.all(promises);
      success = fieldsStatus < 300;
    }

    if (isIncidentManagementPhase(incidentData?.phase)) {
      const { status: updateStatus } = await apiCall(
        isIncidentManager
          ? incidentReportsEndpoints.updateAsIncidentManager(reportId)
          : incidentReportsEndpoints.updateAsActiveUser(reportId),
        { data },
      );
      success = updateStatus < 300;
    }
    if (success) {
      enqueueSnackbar(t(general_messages.data_saved), { variant: 'success' });
    }
    await getData();
    setSent();
    return success;
  };

  useEffect(() => {
    if (isIncidentManagementPhase(incidentData?.phase)) logEvent(logObjects.INCIDENT_MANAGEMENT);
    else logEvent(logObjects.INCIDENT_REPORT);
  }, []);

  const onReport = async () => {
    const saved = await onSave();
    if (saved) {
      await apiCall(
        isIncidentManager
          ? incidentReportsEndpoints.goToNextPhaseAsIncidentManager(reportId)
          : incidentReportsEndpoints.goToNextPhaseAsActiveUser(reportId),
      );
      if (incidentData?.phase === INCIDENT_PHASES.CLOSE_AND_SEND_FEEDBACK) redirect();
      else await getData();
    }
  };

  const isOwnerPhase = useMemo(() => {
    if (!incidentData) return true;
    if (isIncidentManagementPhase(incidentData.phase)) return false;
    // @ts-ignore
    const settings = incidentData.template_blocks.find(({ phase }) => phase === INCIDENT_PHASES.IDENTIFY_AND_REPORT);
    return !settings?.is_editable;
  }, [incidentData]);

  const informationSummaryData = useMemo(() => {
    if (!incidentData) return null;

    const name = { label: guide_messages.report_information_labels.name, value: incidentData.name };
    const createdAt = {
      label: guide_messages.report_information_labels.start,
      value: parseDate(incidentData.created_at),
    };
    const status = {
      label: guide_messages.report_information_labels.status,
      value: t(get(guide_messages.status, incidentData.progress_status, ['In progress'])),
    };
    const updatedAt = {
      label: guide_messages.report_information_labels.last_edit,
      value: parseDate(incidentData.updated_at),
    };

    return isOwnerPhase ? [name, createdAt, status, updatedAt] : [createdAt, updatedAt, status];
  }, [incidentData]);

  const actionButtonLabel = () => {
    if (incidentData?.phase === INCIDENT_PHASES.IDENTIFY_AND_REPORT) return t(guide_messages.submit_incident);
    if (incidentData?.phase === INCIDENT_PHASES.CLOSE_AND_SEND_FEEDBACK) return t(guide_messages.close_incident_report);
    return t(guide_messages.go_to_next_phase);
  };

  const onClose = () => redirect();

  const renderDialogActions = () =>
    isIncidentManagementPhase(incidentData?.phase) && !isIncidentManager ? (
      <CenteredGrid gridGap={2} width='sm' withoutPadding>
        <Button onClick={onClose} variant='outlined'>
          {t(general_messages.close)}
        </Button>
      </CenteredGrid>
    ) : (
      <CenteredGrid gridGap={2} width='sm' withoutPadding>
        {/* @ts-ignore */}
        <ColoredButton customColor='secondary' disabled={!formik.isValid || sending || isOwnerPhase} onClick={onReport} variant='outlined'>
          {actionButtonLabel()}
        </ColoredButton>
        <Button disabled={sending || isOwnerPhase} onClick={onSave}>
          {t(general_messages.save_progress)}
        </Button>
      </CenteredGrid>
    );

  useEffect(() => {
    if (isIncidentManagementPhase(incidentData?.phase)) logEvent(logObjects.INCIDENT_MANAGEMENT);
    else logEvent(logObjects.INCIDENT_REPORT);
  }, []);

  // LOGS data fetcher

  const [logsData, setLogsData] = useState<IncidentReportLogDTO[]>([]);
  const getLogsData = async () => {
    const { data } = await apiCall(incidentReportsEndpoints.getReportLogsAsIncidentManager(reportId), {}, { showError: false });
    setLogsData(data);
  };

  useEffect(() => {
    if (reportId && isIncidentManager) getLogsData();
  }, [reportId]);

  const phasesInfo = useMemo(() => {
    const result = incidentPhaseInOrder.reduce((acc, phase) => {
      acc[phase] = [];
      return acc;
    }, {} as Record<IncidentPhaseT, PhaseInfoT[]>);
    if (!incidentData) return result;

    incidentData.template_blocks.forEach(({ id: blockId, phase, heading, fields }) => {
      result[phase].push({
        id: blockId,
        heading,
        fields: fields.map(({ label, id: fieldId }) => ({
          id: fieldId,
          label,
          value: incidentData.fields_data.find(({ field_id }) => field_id === fieldId)?.value || '',
        })),
      });
    });

    return result;
  }, [incidentData]);

  const styles = useStyles();

  if (sending) return <Loader />;

  if (accessDenied) return <UnauthorizedPage />;

  return (
    <DialogViewWrapper contentSize='lg' maxWidth='lg' title={t(report_messages.report_page_title)}>
      {incidentData ? (
        <>
          <div className={styles.cardTitleWrapper}>
            <Typography className={styles.title} component='h2' variant='h2'>
              {history.action !== 'POP' && (
                <IconButton className={styles.goBack} onClick={() => redirect()}>
                  <ArrowBack />
                </IconButton>
              )}
              {t(guide_messages.report_incident)}
            </Typography>
            <PrintComponent
              ctaProps={{ color: 'secondary', variant: 'outlined' }}
              documentTitle={`Incident Report: ${incidentData.name}_${format(new Date(incidentData.created_at), 'yyyy-MM-dd')}`}
              templateComponent={
                <IncidentReportPrintTemplate
                  categorization={incidentData.categorization}
                  createdAt={new Date(incidentData.created_at)}
                  isGdpr={!!incidentData.is_gdpr}
                  isOngoing={!!incidentData.is_ongoing}
                  logs={logsData.map(log => ({
                    contacted: log.contacted,
                    date_time: new Date(log.date_time),
                    phase: log.phase,
                    id: log.id,
                    notes: log.notes,
                    actions: log.actions,
                  }))}
                  name={incidentData.name}
                  phasesInfo={phasesInfo}
                  priority={incidentData.priority}
                  relatedAssets={incidentData.related_assets}
                  steps={incidentData.steps.map(step => ({
                    id: step.id,
                    comment: step.next_step_description,
                    description: step.description,
                    heading: step.heading,
                  }))}
                  updatedAt={new Date(incidentData.updated_at)}
                />
              }
            />
          </div>
          <CenteredGrid gridGap={2} tight title={t(general_messages.information)} width='lg'>
            {/* @ts-ignore */}
            <InformationSummary data={informationSummaryData} />
            {isIncidentManager && (
              <FormTextInput
                disabled={!!disabled}
                formik={formik}
                id={FORM.NAME}
                label={t(guide_messages.report_information_labels.name)}
              />
            )}
            {isIncidentManager && (
              <FormTextInput
                disabled={!!disabled}
                formik={formik}
                id={FORM.CATEGORIZATION}
                label={t(guide_messages.report_information_labels.categorization)}
              />
            )}
            {isIncidentManagementPhase(incidentData?.phase) && isIncidentManager && (
              <>
                {/* @ts-ignore */}
                <FormCheckboxTile disabled={disabled} formik={formik} id={FORM.IS_GDPR} label={t(guide_messages.is_gdpr_label)} />
                {/* @ts-ignore */}
                <FormCheckboxTile disabled={disabled} formik={formik} id={FORM.IS_ONGOING} label={t(guide_messages.is_ongoing_label)} />
                <FormSelect
                  disabled={!!disabled}
                  formik={formik}
                  id={FORM.PRIORITY}
                  label={t(guide_messages.priority_label)}
                  options={TRANSLATABLE_INCIDENT_PRIORITIES_DICTIONARY}
                />
              </>
            )}
            <FormFileInput
              formik={formik}
              id={FORM.ATTACHMENTS}
              label={t(title_messages.add_attachment)}
              listOnly={isOwnerPhase}
              multiple
            />
          </CenteredGrid>
          {isIncidentManager && (
            <>
              <AssetsInIncidentReport incidentReportId={reportId} isLoading={loading} relatedAssets={incidentData.related_assets} />
              <CenteredGrid
                component={CustomAccordion}
                heading={<FormTitle>{t(guide_messages.incident_management_log)}</FormTitle>}
                id='logs'
                width='sm'
              >
                <IncidentLogs logsData={logsData} readonly={!!disabled} refreshData={getLogsData} reportId={reportId} />
              </CenteredGrid>
            </>
          )}
          <CenteredGrid
            component={CustomAccordion}
            heading={<FormTitle>{t(guide_messages.incident_steps_header)}</FormTitle>}
            id='steps'
            width='sm'
          >
            <TakenStepsSummary steps={incidentData?.steps} />
          </CenteredGrid>
          {getPreviousPhases(incidentData?.phase, isIncidentManager).map(phase => (
            <CenteredGrid
              key={phase}
              component={CustomAccordion}
              heading={<FormTitle>{t(incidentPhaseResolver(phase))}</FormTitle>}
              id='prevPhases'
              width='sm'
            >
              <div className={styles.blockWrapper}>
                {incidentData?.template_blocks
                  .filter(block => block.phase === phase)
                  .map(block => (
                    <IncidentFormBlock key={block.id} notEditable {...block} formik={formik} />
                  ))}
              </div>
            </CenteredGrid>
          ))}
          {(isIncidentManager || !isIncidentManagementPhase(incidentData?.phase)) && (
            <form id={FORM_ID} onSubmit={formik.handleSubmit}>
              <CenteredGrid
                component={CustomAccordion as unknown as JSX.Element}
                heading={<FormTitle>{t(incidentPhaseResolver(incidentData?.phase))}</FormTitle>}
                id='currPhase'
                width='sm'
                withoutPadding
              >
                <div className={styles.blockWrapper}>
                  {incidentData?.template_blocks
                    .filter(block => block.phase === incidentData?.phase)
                    .map(block => (
                      <IncidentFormBlock key={block.id} notEditable={!block.is_editable || !!disabled} {...block} formik={formik} />
                    ))}
                </div>
              </CenteredGrid>
            </form>
          )}
          {!disabled && renderDialogActions()}
        </>
      ) : (
        <Loader inner />
      )}
    </DialogViewWrapper>
  );
};

export default SingleIncidentReportPage;
