import { AxiosResponse } from 'axios';

import { usersApi } from 'config/api/users/users';
import auth_messages from 'messages/auth_messages';
import general_messages from 'messages/general_messages';

type Callbacks = {
  onSuccess: () => Promise<void> | void;
  onError: (message: string[]) => void;
};

const validateCampaignCode = async (codeToValidate: string | null, callbacks: Callbacks) => {
  if (!codeToValidate) {
    await callbacks.onSuccess();
    return true;
  }
  try {
    const { status } = await usersApi.checkCampaignCode(codeToValidate); // TODO move to react-query
    await callbacks.onSuccess();
    return status === 200;
  } catch (error) {
    const { response } = error as { response: AxiosResponse<{ message: 'outdated' | 'limit_reached' }> };
    let message;
    if (response.status === 404) {
      message = auth_messages.campaign_code_error.not_exist;
    } else if (response.status === 400) {
      // eslint-disable-next-line default-case
      switch (response.data.message) {
        case 'outdated':
          message = auth_messages.campaign_code_error.outdated;
          break;
        case 'limit_reached':
          message = auth_messages.campaign_code_error.limit_reached;
          break;
      }
    } else {
      message = general_messages.api_error;
    }
    callbacks.onError(message);
    return false;
  }
};

export default validateCampaignCode;
