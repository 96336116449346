import axios from 'axios';

import createUrlWithParams from 'helpers/createUrlWithParams';
import { ParamsType } from 'helpers/createUrlWithParams/createUrlWithParams';
import { Id } from 'types/Id';

import { FiltersForRequestT } from '../../services/parseFiltersForRequest/parseFiltersForRequest';

import METHOD from './_methods';
import { BasePaginatedQueryParams } from './_types/BasePaginatedQueryParams';
import { IncidentReportInListDTO, IncidentReportRelatedIssueInput } from './incident_reports.types';

const incidentReportsEndpoints = {
  /**
   * pass guide_progress_id
   * @returns {{method: string, url: string}}
   */
  removeAttachment: (attachment_id: Id) => ({ url: `api/v1/attachments/${attachment_id}/`, method: METHOD.DELETE }),
  createFromGuide: () => ({ url: '/api/v1/incident_manager/reports/', method: METHOD.POST }),
  getAll: () => ({ url: '/api/v1/incident_manager/reports/', method: METHOD.GET }),
  getOne: (report_id: Id) => ({ url: `/api/v1/incident_manager/reports/${report_id}/`, method: METHOD.GET }),
  update: (report_id: Id) => ({ url: `/api/v1/incident_manager/reports/${report_id}/`, method: METHOD.PUT }),
  updateFields: (report_id: Id) => ({ url: `/api/v1/incident_manager/reports/${report_id}/fields/`, method: METHOD.PATCH }),
  goToNextPhase: (report_id: Id) => ({ url: `/api/v1/incident_manager/reports/${report_id}/next_phase/`, method: METHOD.POST }),
  addAttachment: (report_id: Id) => ({ url: `/api/v1/incident_manager/reports/${report_id}/add_attachment/`, method: METHOD.POST }),

  // PS adviser
  getAllAsAdviser: () => ({ url: '/api/v1/adviser/reports/', method: METHOD.GET }),
  getOneAsAdviser: (report_id: Id) => ({ url: `/api/v1/adviser/reports/${report_id}/`, method: METHOD.GET }),
  createFromGuideAsAdviser: () => ({ url: '/api/v1/adviser/reports/', method: METHOD.POST }),

  // active user
  getAllAsActiveUser: (params: BasePaginatedQueryParams & { asset?: Id }) => ({
    url: createUrlWithParams('/api/v1/active_user/reports/', params),
    method: METHOD.GET,
  }),
  getOneAsActiveUser: (report_id: Id) => ({ url: `/api/v1/active_user/reports/${report_id}/`, method: METHOD.GET }),
  updateAsActiveUser: (report_id: Id) => ({ url: `/api/v1/active_user/reports/${report_id}`, method: METHOD.PUT }),
  createFromGuideAsActiveUser: () => ({ url: '/api/v1/active_user/reports/', method: METHOD.POST }),
  updateFieldsAsActiveUser: (report_id: Id) => ({ url: `/api/v1/active_user/reports/${report_id}/fields/`, method: METHOD.PATCH }),
  goToNextPhaseAsActiveUser: (report_id: Id) => ({ url: `/api/v1/active_user/reports/${report_id}/next_phase/`, method: METHOD.POST }),
  addAttachmentAsActiveUser: (report_id: Id) => ({ url: `/api/v1/active_user/reports/${report_id}/add_attachment/`, method: METHOD.POST }),

  // incident manager
  getAllAsIncidentManager: () => ({ url: '/api/v1/incident_manager/reports/', method: METHOD.GET }),
  getAllAsIncidentManagerWithParams: (params: ParamsType) => ({
    url: createUrlWithParams('/api/v1/incident_manager/reports/', params),
    method: METHOD.GET,
  }),
  getAllAsIncidentManagerWithFilters: (params: ParamsType) => ({
    url: createUrlWithParams('/api/v1/reports/search', params),
    method: METHOD.POST,
  }),
  getOneAsIncidentManager: (report_id: Id) => ({ url: `/api/v1/incident_manager/reports/${report_id}/`, method: METHOD.GET }),
  createFromGuideAsIncidentManager: () => ({ url: '/api/v1/incident_manager/reports/', method: METHOD.POST }),
  updateAsIncidentManager: (report_id: Id) => ({ url: `/api/v1/incident_manager/reports/${report_id}/`, method: METHOD.PUT }),
  updateFieldsAsIncidentManager: (report_id: Id) => ({
    url: `/api/v1/incident_manager/reports/${report_id}/fields/`,
    method: METHOD.PATCH,
  }),
  goToNextPhaseAsIncidentManager: (report_id: Id) => ({
    url: `/api/v1/incident_manager/reports/${report_id}/next_phase/`,
    method: METHOD.POST,
  }),
  addAttachmentAsIncidentManager: (report_id: Id) => ({
    url: `/api/v1/incident_manager/reports/${report_id}/add_attachment/`,
    method: METHOD.POST,
  }),

  // REPORT LOGS
  getReportLogsAsIncidentManager: (report_id: Id) => ({
    url: `api/v1/incident_manager/reports/${report_id}/manage_logs/`,
    method: METHOD.GET,
  }),
  addReportLog: () => ({ url: 'api/v1/incident_manage_logs/', method: METHOD.POST }),
};

export const incidentReportsApi = {
  getReportsKPI: async (filters: FiltersForRequestT) => {
    const { data } = await axios.get(createUrlWithParams('/api/v1/reports/kpi/', filters));
    return data;
  },
  addRelatedAsset: (reportId: Id) => (data: IncidentReportRelatedIssueInput) =>
    axios.post(`/api/v1/incident_manager/reports/${reportId}/add_related_asset/`, {
      asset: data.asset,
      description: data.description,
    }),
  updateRelatedAsset: (relatedAssetId: Id) => (data: IncidentReportRelatedIssueInput) =>
    axios.put(`/api/v1/incident_manager/reports/related_assets/${relatedAssetId}/`, {
      asset: data.asset,
      description: data.description,
    }),
  removeRelatedAsset: (relatedAssetId: Id) => axios.delete(`/api/v1/incident_manager/reports/related_assets/${relatedAssetId}/`),

  getAllAsActiveUser: async (params: BasePaginatedQueryParams & { asset?: Id }): Promise<IncidentReportInListDTO[]> => {
    const { data } = await axios.get(createUrlWithParams('/api/v1/active_user/reports/', params));
    return data;
  },
};

// @ts-ignore
export default incidentReportsEndpoints;
