import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Collapse, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import * as yup from 'yup';

import CenteredGrid from 'components/CenteredGrid/CenteredGrid';
import ColoredButton from 'components/ColoredButton';
import FormTextInput from 'components/FormTextInput';
import authTotpDevicesApi from 'config/api/authTotpDevices/authTotpDevices';
import manageTotpDevicesApi from 'config/api/manageTotpDevices/manageTotpDevices';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import instances from 'config/constants/instances';
import exhaustiveGuard from 'helpers/exhaustiveGuard/exhaustiveGuard';
import useLoadingState from 'hooks/useLoadingState';
import general_messages from 'messages/general_messages';
import otp_messages from 'messages/otp_messages';
import validation_messages from 'messages/validation_messages';

import ButtonsGrid from '../_components/ButtonsGrid';
import DialogGrid from '../_components/DialogGrid';

import useStyles from './OTPMethodDialog.styles';

type Step = 1 | 2 | 3;
// STEPS:
// 1. Pass name of the OTP method
// 2. Scan QR code to add it to device
// 3. Confirm OTP method by passing the code from the device

export type Props = {
  open: boolean;
  onClose: (withActivation?: boolean) => void;
  initialStep?: Step;
  deviceId?: number;
  isEditMode?: boolean;
};

export type DynamicOTPMethodDialogProps = Omit<Props, 'onClose' | 'open'>;

export type OTPMethodDialogNameForm = {
  name: string;
};

export type OTPMethodDialogTokenForm = {
  token: string;
};

const isProduction = process.env.REACT_APP_INSTANCE === instances.PRODUCTION;

const FORM_ID = 'OTPName';

// TODO test confirming new device (id from API)
const OTPMethodDialog: React.FC<Props> = ({ open, onClose: closeDialog, initialStep, deviceId: initialDeviceId, isEditMode }) => {
  if (isEditMode && ![1, undefined].includes(initialStep)) {
    const errorMsg = 'Initial step must be 1 or undefined when editing OTP method';
    // eslint-disable-next-line no-console
    if (isProduction) console.error(errorMsg);
    else throw new Error(errorMsg);
  }

  const queryClient = useQueryClient();
  const tokenInputRef = useRef<HTMLInputElement>(null);

  const { data: initialData } = useQuery(
    [QUERY_KEYS.GET_OTP_DEVICE_INFO, initialDeviceId],
    () => manageTotpDevicesApi.getSingleTotpDevice(initialDeviceId as number),
    {
      enabled: !!initialDeviceId,
    },
  );
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { loading, setLoading, setLoaded } = useLoadingState(false);

  const [step, setStep] = useState<Step>(initialStep || 1);
  const [deviceId, setDeviceId] = useState(initialDeviceId);

  const onClose = (withActivation?: boolean) => {
    queryClient.invalidateQueries(QUERY_KEYS.GET_OTP_METHODS);
    closeDialog(withActivation);
  };

  const closeWithoutActivation = () => onClose();

  const createMutation = useMutation(manageTotpDevicesApi.addTotpDevice);
  const verifyMutation = useMutation(authTotpDevicesApi.verifyTotpToken);
  const editMutation = useMutation(manageTotpDevicesApi.editTotpDevice);
  const isSending = createMutation.isLoading || verifyMutation.isLoading || editMutation.isLoading;

  const [imageSrcRaw, setImageSrcRaw] = useState<null | string>('');
  const imageSrc = useMemo(() => {
    if (!imageSrcRaw) return null;
    return `data:image/png;base64, ${imageSrcRaw}`;
  }, [imageSrcRaw]);

  const onSubmitName = async (formData: OTPMethodDialogNameForm) => {
    if (createMutation.isLoading) return;
    const { qrCode, id } = await createMutation.mutateAsync(formData);
    setImageSrcRaw(qrCode);
    setDeviceId(id);
  };

  const formikName = useFormik<OTPMethodDialogNameForm>({
    initialValues: {
      name: '',
    },
    validationSchema: yup.object({
      name: yup.string().required(t(validation_messages.required)),
    }),
    onSubmit: onSubmitName,
  });

  const onSubmitToken = async (formData: OTPMethodDialogTokenForm) => {
    if (deviceId) {
      await verifyMutation.mutateAsync({ deviceId, otpToken: formData.token });
    } else {
      const error = 'Device id is missing!';
      // eslint-disable-next-line no-console
      if (isProduction) console.error(error);
      else throw Error(error);
    }
  };

  const formikToken = useFormik<OTPMethodDialogTokenForm>({
    initialValues: {
      token: '',
    },
    validationSchema: yup.object({
      token: yup.string().required(t(validation_messages.required)),
    }),
    onSubmit: onSubmitToken,
  });

  useEffect(() => {
    if (initialData) {
      formikName.setValues({ name: initialData.name });
      setImageSrcRaw(initialData.qrCode);
    }
  }, [initialData]);

  useEffect(() => {
    setTimeout(() => {
      const selectedSection = document.getElementById(`step-${step}`);
      if (selectedSection) selectedSection.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
      if (step === 3) tokenInputRef.current?.focus();
    }, 250);
  }, [step]);

  const resolveActionButtonClick = useCallback(async () => {
    if (isEditMode && deviceId) {
      setLoading();
      await editMutation.mutateAsync({ deviceId, name: formikName.values.name });
      setLoaded();
      onClose();
      return;
    }

    switch (step) {
      case 1: {
        setLoading();
        const errors = await formikName.validateForm();
        if (Object.values(errors).length) formikName.setErrors(errors);
        else {
          await formikName.submitForm();
          setStep(2);
        }
        setLoaded();
        break;
      }
      case 2: {
        setStep(3);
        break;
      }
      case 3: {
        setLoading();
        const errors = await formikToken.validateForm();
        if (Object.values(errors).length) formikToken.setErrors(errors);
        else {
          try {
            await formikToken.submitForm();
            enqueueSnackbar(t(otp_messages.create_device_dialog.successfully_created), { variant: 'success' });
            onClose(true);
          } catch (e) {
            enqueueSnackbar(t(otp_messages.create_device_dialog.invalid_token), { variant: 'error' });
            formikToken.resetForm();
          }
        }
        setLoaded();
        break;
      }
      default: {
        exhaustiveGuard(step);
      }
    }
  }, [step, formikName.values]);

  const buttonLabel = useMemo(() => {
    if (isEditMode) return t(general_messages.save);
    if (step === 3) return t(otp_messages.create_device_dialog.confirmation_button_label);
    return t(general_messages.next_step);
  }, [step]);

  useEffect(() => {
    const onKeyboardClick = (e: KeyboardEvent) => {
      const isEnter = e && e.key === 'Enter';
      if (isEnter) resolveActionButtonClick();
    };

    window.addEventListener('keydown', onKeyboardClick);
    return () => window.removeEventListener('keydown', onKeyboardClick);
  }, [resolveActionButtonClick]);

  const styles = useStyles();

  return (
    // @ts-ignore
    <DialogGrid
      dialogActions={
        <ButtonsGrid>
          {/* @ts-ignore */}
          <ColoredButton customColor='none' disabled={loading} onClick={closeWithoutActivation} variant='outlined'>
            {t(general_messages.cancel)}
          </ColoredButton>
          {/* @ts-ignore */}
          <ColoredButton
            customColor='secondary'
            disabled={loading || isSending}
            onClick={resolveActionButtonClick}
            showLoader={loading}
            variant='outlined'
          >
            {buttonLabel}
          </ColoredButton>
        </ButtonsGrid>
      }
      onClose={closeWithoutActivation}
      open={open}
      title={t(otp_messages.create_device_dialog.dialog_title)}
    >
      {/* STEP 1 */}
      <div className={clsx(styles.stepWrapper, step === 1 && styles.active)} id='step-1'>
        <Typography variant='h3'>{t(otp_messages.create_device_dialog.step_one_title)}</Typography>
        <Typography variant='caption'>{t(otp_messages.create_device_dialog.step_one_description)}</Typography>
        <form id={FORM_ID} onSubmit={formikName.handleSubmit}>
          {/* @ts-ignore */}
          <CenteredGrid gridGap={2} withoutPadding>
            <FormTextInput
              disabled={step !== 1}
              formik={formikName}
              id='name'
              label={t(otp_messages.create_device_dialog.description_input_label)}
            />
          </CenteredGrid>
        </form>
      </div>
      {/*  STEP 2 */}
      <Collapse in={step >= 2}>
        <div className={clsx(styles.stepWrapper, step === 2 && styles.active)} id='step-2'>
          <Typography variant='h3'>{t(otp_messages.create_device_dialog.step_two_title)}</Typography>
          <Typography variant='caption'>{t(otp_messages.create_device_dialog.step_two_description)}</Typography>
          {imageSrc && <img alt='QR code' className={styles.qrCode} src={imageSrc} />}
        </div>
      </Collapse>
      <Collapse in={step >= 3}>
        <div className={clsx(styles.stepWrapper, step === 3 && styles.active)} id='step-3'>
          <Typography variant='h3'>{t(otp_messages.create_device_dialog.step_three_title)}</Typography>
          <Typography variant='caption'>{t(otp_messages.create_device_dialog.step_three_description)}</Typography>
          {/* @ts-ignore */}
          <CenteredGrid gridGap={2} withoutPadding>
            <FormTextInput
              formik={formikToken}
              id='token'
              inputProps={{ autocomplete: 'one-time-code' }}
              inputRef={tokenInputRef}
              label={t(otp_messages.create_device_dialog.confirmation_input_label)}
            />
          </CenteredGrid>
        </div>
      </Collapse>
    </DialogGrid>
  );
};

export default OTPMethodDialog;
