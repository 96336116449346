import React, { useMemo } from 'react';

import { Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import CenteredGrid from 'components/CenteredGrid/CenteredGrid';
import ColoredButton from 'components/ColoredButton';
import FormSelect from 'components/FormSelect';
import FormTextInput from 'components/FormTextInput';
import TypographyWithHTML from 'components/TypographyWithHTML/TypographyWithHTML';
import activeUserRiskAnalysesApi from 'config/api/activeUserRiskAnalyses/activeUserRiskAnalyses';
import { ActiveUserRiskAnalysis, RiskAnalysisStatus } from 'config/api/activeUserRiskAnalyses/activeUserRiskAnalyses.types';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import DATE_FORMAT from 'config/constants/DATE_FORMAT';
import RISK_ANALYSIS_STATUS_SELECT from 'config/dictionaries/RISK_ANALYSIS_STATUS_SELECT';
import asset_risk_messages from 'messages/asset_risk_messages';
import general_messages from 'messages/general_messages';
import { Id } from 'types/Id';

import FormDateInput from '../../FormDateInput';
import ButtonsGrid from '../_components/ButtonsGrid';
import DialogGrid from '../_components/DialogGrid';

import useStyles from './EditRiskAnalysisMetadataDialog.styles';

type Props = {
  open: boolean;
  onClose: () => void;
  riskAnalysisId: Id;
  initialData?: ActiveUserRiskAnalysis;
};

type AnalysisInput = {
  participants: string;
  status: RiskAnalysisStatus;
  date: Date;
};

const FORM_ID = 'risk-analysis-form_base-data';

const EditRiskAnalysisMetadataDialog: React.FC<Props> = ({ open, onClose, initialData, riskAnalysisId }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const setStateMutation = useMutation(activeUserRiskAnalysesApi.setStatusOfRiskAnalysis);
  const updateMutation = useMutation(activeUserRiskAnalysesApi.updateRiskAnalysis);

  const options = useMemo(
    () =>
      RISK_ANALYSIS_STATUS_SELECT.map(({ key, label }) => ({
        key,
        label,
        disabled: (key === 'completed' && !initialData?.canBeClosed) || (key === 'in_progress' && !initialData?.canBeReopened),
      })),
    [initialData],
  );

  const onSubmitMetadata = async (formData: AnalysisInput) => {
    const statusChanged = initialData?.status !== formData.status;
    const participantsChanged = initialData?.participants !== formData.participants;
    const dateChanged = initialData?.date !== formData.date;
    await Promise.all([
      (participantsChanged || dateChanged) &&
        updateMutation.mutateAsync({
          data: { participants: formData.participants, date: format(formData.date, DATE_FORMAT.defaultDate) },
          analysisId: riskAnalysisId,
        }),
      statusChanged && setStateMutation.mutateAsync({ newStatus: formData.status, analysisId: riskAnalysisId }),
    ]);
    await queryClient.invalidateQueries([QUERY_KEYS.GET_SINGLE_RISK_ANALYSIS, riskAnalysisId]);
    onClose();
    enqueueSnackbar(t(general_messages.data_saved), { variant: 'success' });
  };
  const formik = useFormik<AnalysisInput>({
    initialValues: {
      participants: initialData?.participants || '',
      status: initialData?.status || 'in_progress',
      date: initialData?.date || new Date(),
    },
    onSubmit: onSubmitMetadata,
    enableReinitialize: true,
  });

  const isMutating = useMemo(() => setStateMutation.isLoading || updateMutation.isLoading, [setStateMutation, updateMutation]);

  const editDisabled = initialData?.status === 'completed';

  const styles = useStyles();
  return (
    // @ts-ignore
    <DialogGrid
      dialogActions={
        <ButtonsGrid>
          {/* @ts-ignore */}
          <ColoredButton customColor='none' onClick={onClose} variant='outlined'>
            {t(general_messages.cancel)}
          </ColoredButton>
          {/* @ts-ignore */}
          <ColoredButton customColor='secondary' disabled={isMutating} form={FORM_ID} type='submit' variant='outlined'>
            {t(general_messages.save)}
          </ColoredButton>
        </ButtonsGrid>
      }
      onClose={onClose}
      open={open}
      title={t(asset_risk_messages.details_page.metadata_dialog_title)}
    >
      <form id={FORM_ID} onSubmit={formik.handleSubmit}>
        {/* @ts-ignore */}
        <CenteredGrid gridGap={2} withoutPadding>
          <div className={styles.statusContainer}>
            <FormSelect formik={formik} id='status' label={t(asset_risk_messages.details_page.status_label)} options={options} />
            <Tooltip
              title={<TypographyWithHTML variant='caption'>{t(asset_risk_messages.disabled_info.status_changing_info)}</TypographyWithHTML>}
            >
              <InfoIcon className={styles.icon} />
            </Tooltip>
          </div>
          <Tooltip
            title={
              <TypographyWithHTML variant='caption'>
                {editDisabled ? t(asset_risk_messages.disabled_info.metadata_info) : ''}
              </TypographyWithHTML>
            }
          >
            <FormTextInput
              disabled={editDisabled}
              formik={formik}
              id='participants'
              label={t(asset_risk_messages.details_page.participants_label)}
              multiline
              rows={4}
            />
          </Tooltip>
          <Tooltip
            title={
              <TypographyWithHTML variant='caption'>
                {editDisabled ? t(asset_risk_messages.disabled_info.metadata_info) : ''}
              </TypographyWithHTML>
            }
          >
            <FormDateInput
              dateTime={false}
              disabled={editDisabled}
              formik={formik}
              id='date'
              label={t(asset_risk_messages.details_page.date_of_analysis_label)}
            />
          </Tooltip>
        </CenteredGrid>
      </form>
    </DialogGrid>
  );
};

export default EditRiskAnalysisMetadataDialog;
