import React, { useState } from 'react';

import CarouselWrapper from 'components/CarouselWrapper/CarouselWrapper';
import MobileGuttersContainer from 'components/MobileGuttersContainer';
import Section from 'components/Section';
import UserKpiSection from 'components/UserKpiSection/UserKpiSection';
import title_messages from 'messages/title_messages';
import { activityTypeFilterFallback } from 'services/_filters/parsers/activityTypeFilter';
import parseFiltersForRequest from 'services/parseFiltersForRequest';

import ActivitiesTable from '../ActivitiesTable';

const ActivitiesSection = () => {
  const [filters, setFilters] = useState({});

  const filtersForRequest = parseFiltersForRequest(filters, { object_content_type: activityTypeFilterFallback });

  return (
    // @ts-ignore
    <Section showSeparator smallPadding title={title_messages.activity} titleWrapper={MobileGuttersContainer}>
      <CarouselWrapper noTopPadding>
        <UserKpiSection />
      </CarouselWrapper>
      <MobileGuttersContainer>
        <ActivitiesTable filters={filters} filtersForRequest={filtersForRequest} setFilters={setFilters} />
      </MobileGuttersContainer>
    </Section>
  );
};

export default ActivitiesSection;
